.bankLogosContainer {
    padding: 1rem 0;
  }
  
  .bankLogosContainer h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #ffffff;
  }
  
  .logoGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .logoItem {
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 0.5rem;
    transition: transform 0.3s ease;
  }
  
  .logoItem:hover {
    transform: scale(1.05);
  }
  
  .logoItem img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .bankLogosContainer h3 {
      font-size: 1rem;
    }
  
    .logoItem {
      width: 80px;
      height: 50px;
    }
  
    .logoGrid {
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .bankLogosContainer {
      padding: 0.5rem 0;
    }
  
    .bankLogosContainer h3 {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  
    .logoItem {
      width: 60px;
      height: 40px;
    }
  
    .logoGrid {
      gap: 0.25rem;
    }
  }
  
  