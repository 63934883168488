.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: center;
}

.section {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 2rem 2rem;
}

.sectionTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.welcomeContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.btnVisit {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btnVisit:hover {
  background-color: var(--secondary-color);
}

.aboutUsSection {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 2rem 2rem;
}

.aboutUsTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.aboutUsContent {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.aboutUsText,
.aboutUsFeatures {
  flex: 1;
  min-width: 300px;
}

.testimonials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.testimonials blockquote {
  background-color: var(--accent-color);
  border-radius: 10px;
  padding: 1.5rem;
  font-style: italic;
}

.callToAction {
  text-align: center;
  margin-top: 2rem;
}

.mapLink {
  display: inline-block;
  background-color: var(--primary-color);
  color: rgb(255, 255, 255);
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.mapLink:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .carouselContainer {
    height: 300px;
  }

  .aboutUsContent {
    flex-direction: column;
  }

  .testimonials {
    grid-template-columns: 1fr;
  }
}

