:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --border-color: #ced4da;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: var(--dark-color);
  background-color: var(--light-color);
}

.admin-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.admin-header {
  display: flex;
  justify-self: center;
  margin-bottom: 2rem;
}

.admin-title {
  text-align: center;
  margin-bottom: 0;
  color: var(--primary-color);
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.admin-content {
  gap: 2rem;
}

.admin-form {
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vehicle-list {
  flex: 1;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-row {
  gap: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--dark-color);
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: border-color 0.3s ease;
}
.form-group textarea {
  height: 200px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.submit-button,
.edit-button,
.delete-button {
  color: white;
  border: none;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: var(--primary-color);
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.edit-button {
  background-color: var(--success-color);
}

.edit-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: var(--danger-color);
}

.delete-button:hover {
  background-color: #c82333;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-preview-container {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  cursor: move;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
}

.vehicle-list-title {
  margin-bottom: 1rem;
  color: var(--primary-color);
  background-color: #f8f9fa;
  padding: 3px;
  text-align: center;
  border-radius: 10px;
}

.vehicle-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.vehicle-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.vehicle-card:hover {
  transform: translateY(-5px);
}

.vehicle-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.vehicle-info {
  flex-grow: 1;
  padding: 1rem 0;
}

.vehicle-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.vehicle-details {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.vehicle-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.vehicle-actions button {
  flex: 1;
  margin: 0 0.25rem;
}
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-title {
  margin-bottom: 0;
}

.logout-button {
  background-color: var(--danger-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .admin-container {
    padding: 1rem;
  }

  .admin-content {
    flex-direction: column;
  }

  .admin-form {
    width: 88%;
  }
  .vehicle-list {
    width: 100%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .vehicle-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .admin-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-title {
    margin-bottom: 1rem;
  }

  .vehicle-grid {
    grid-template-columns: 1fr;
  }

  .vehicle-card {
    max-width: 100%;
  }

  .vehicle-actions {
    flex-direction: column;
  }

  .vehicle-actions button {
    width: 100%;
    margin: 0.25rem 0;
  }
}

.no-images {
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: var(--secondary-color);
  border: 2px dashed var(--border-color);
  border-radius: 4px;
}

/* Feature button styles */
.feature-button {
  background-color: #ffd700;
  color: #000;
  border: none;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feature-button:hover {
  background-color: #ffcc00;
}

.feature-button.featured {
  background-color: #28a745;
  color: white;
}

.feature-button.featured:hover {
  background-color: #218838;
}

/* Loading overlay styles */
/* Estilização do container do loading */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa a altura total da tela */
  background-color: rgba(255, 255, 255, 0.8); /* Fundo levemente transparente */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

/* Animação do spinner */
.loading-spinner::after {
  content: "";
  width: 60px;
  height: 60px;
  border: 6px solid #ccc;
  border-top-color: #007bff; /* Cor azul do spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animação de rotação */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive styles for feature button */
@media (max-width: 768px) {
  .vehicle-actions {
    flex-direction: column;
  }

  .vehicle-actions button {
    width: 100%;
    margin: 0.25rem 0;
  }
}

