/* LegalPage.module.css */
.legalPage {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  background-color: #050b7d;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 30px;
}

.title {
  margin: 0;
  font-size: 2.5em;
}

.main {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 30px;
}

.sectionTitle {
  color: #050b7d;
}

.list {
  padding-left: 20px;
}

.listItem {
  margin-bottom: 10px;
}

.link {
  color: #050b7d;
  text-decoration: none;
}

@media (max-width: 600px) {
  .legalPage {
    padding: 10px;
  }

  .header {
    padding: 15px 0;
  }

  .title {
    font-size: 2em;
  }

  .main {
    padding: 20px;
  }
}